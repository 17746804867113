import React, { Fragment, useState } from "react";
import {
  formatDate,
  EuiInMemoryTable,
  EuiSpacer,
  EuiAvatar,
  EuiBadge,
} from "@elastic/eui";
import { useEffect } from "react";
import OrganizationService from "../../services/organization-service";

export const OrganizationTable = (props) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const token = sessionStorage.getItem("krakenT");
    OrganizationService.getOrganizations(token)
      .then((response) => {
        const companies = [];
        for (let company of response.data.message) {
          companies.push(company);
        }
        setCompanies(companies);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const companyClicked = (item) => {
    props.companySelected(item);
  };
  const actions = [
    {
      name: "Edit",
      description: "Edit",
      icon: "indexEdit",
      type: "icon",
      onClick: (item) => companyClicked(item),
    },
  ];

  const columns = [
    {
      name: "",
      actions,
      width: 30,
    },
    {
      name: "Name",
      render: (item) => (
        <>
          <EuiAvatar size="s" name="Cat" imageUrl={item.companyLogo} />
          &nbsp; {item.nameLong}
        </>
      ),
      width: 300,
      truncateText: true,
    },
    {
      field: "companyCode",
      name: "Company Code",
      sortable: true,
      truncateText: true,
    },
    {
      field: "cif",
      name: "CIF",
      sortable: true,
      truncateText: true,
    },
    {
      field: "contact",
      name: "Contact",
      sortable: true,
      truncateText: true,
    },

    {
      field: "mail",
      name: "Mail",
      sortable: true,
      truncateText: true,
    },
    {
      field: "phone",
      name: "Phone",
      sortable: true,
      truncateText: true,
    },
    {
      field: "createdAt",
      name: "Date Creation",
      dataType: "date",
      render: (date) => formatDate(date, "dobLong"),
      sortable: true,
      truncateText: true,
    },
    {
      field: "updatedAt",
      name: "Last Update",
      dataType: "date",
      render: (date) => formatDate(date, "dobLong"),
      sortable: true,
      truncateText: true,
    },
    {
      field: "status",
      name: "Status",
      render: (status) => <EuiBadge color="success">{status}</EuiBadge>,
      truncateText: true,
    },
  ];

  const search = {
    box: {
      incremental: true,
      schema: true,
    },
  };

  return (
    <Fragment>
      <EuiSpacer size="l" />
      <EuiInMemoryTable
        tableCaption="Demo of EuiInMemoryTable with search"
        items={companies}
        columns={columns}
        search={search}
        pagination={true}
        sorting={true}
      />
    </Fragment>
  );
};
