import http from "../http-common";

const teamsTokens = (token, id, admin) => {
  return http.get(
    `/api/v1/serviceStatus/teamsTokens?companyId=${id}&companyAdmin=${admin}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const ServicesStatus = {
  teamsTokens,
};

export default ServicesStatus;
