import http from "../http-common";

const newProcess = async (token, organization) => {
  return http.post(
    `/api/v1/process/create?organizationId=${organization}&name=New Extension`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const dataValidation = async (token, transactionId, companyId) => {
  return http.get(
    `/api/v1/extension/steps/cero?transactionId=${transactionId}&companyId=${companyId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const msStuffs = async (
  companyAdmin,
  token,
  transactionId,
  companyId,
  data
) => {
  return http.post(
    `/api/v1/extension/steps/one?transactionId=${transactionId}&companyId=${companyId}&companyAdmin=${companyAdmin}`,
    { data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const voiceHubStuffs = async (
  companyAdmin,
  token,
  transactionId,
  companyId,
  data
) => {
  return http.post(
    `/api/v1/extension/steps/two?transactionId=${transactionId}&companyId=${companyId}&companyAdmin=${companyAdmin}`,
    { data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getExtensionsByCompanyId = async (token, companyId) => {
  return http.get(`/api/v1/extension/getByCompanyId?companyId=${companyId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const newExtension = async (token, transactionId, companyId, data) => {
  return http.post(
    `/api/v1/extension/create?transactionId=${transactionId}&companyId=${companyId}`,
    { data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getProcess = async (token, processId) => {
  return http.get(`/api/v1/process/get?processId=${processId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getQueuesFromExtension = async (token, extension) => {
  return http.get(
    `/api/v1/extension/getQueuesFromUser?extension=${extension}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const refreshHints = async (token, extension, teamsID) => {
  return http.get(
    `/api/v1/extension/refreshHints?extension=${extension}&teamsID=${teamsID}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const ExtensionService = {
  dataValidation,
  newProcess,
  msStuffs,
  voiceHubStuffs,
  getExtensionsByCompanyId,
  newExtension,
  getProcess,
  getQueuesFromExtension,
  refreshHints,
};

export default ExtensionService;
