import React from "react";
import { EuiBreadcrumbs, EuiPanel } from "@elastic/eui";
import { useState } from "react";
import { OrganizationTable } from "./OrganizationsTable";
import { OrganizationDetails } from "./OrganizationDetails";

const Organizations = () => {
  const [isCompaniesSelected, setIsCompaniesSelected] = useState(true);
  const [companySelectedId, setCompanySelectedId] = useState();
  const [companySelectedCode, setCompanySelectedCode] = useState();
  const [breadcrumbs, setBreadCrumbs] = useState([
    {
      text: "Companies",
      href: "#",
      onClick: (e) => {
        onClickCompanies(e);
      },
      "data-test-subj": "breadcrumbsAnimals",
    },
  ]);

  const onClickCompanies = (e) => {
    e.preventDefault();
    setIsCompaniesSelected(true);
    setBreadCrumbs([
      {
        text: "Companies",
        href: "#",
        onClick: (e) => {
          onClickCompanies(e);
        },
        "data-test-subj": "breadcrumbsAnimals",
      },
    ]);
  };

  const onCompanySelected = (company) => {
    setIsCompaniesSelected(false);
    setBreadCrumbs([
      {
        text: "Companies",
        href: "#",
        onClick: (e) => {
          onClickCompanies(e);
        },
        "data-test-subj": "breadcrumbsAnimals",
      },
      {
        text: company.nameLong,
      },
    ]);
    setCompanySelectedId(company.id);

    setCompanySelectedCode(company.companyCode);
  };

  return (
    <>
      <div
        style={{
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <EuiPanel>
          <EuiBreadcrumbs
            breadcrumbs={breadcrumbs}
            truncate={false}
            aria-label="An example of EuiBreadcrumbs"
          />
        </EuiPanel>
      </div>
      {isCompaniesSelected ? (
        <div
          style={{
            marginLeft: 10,
            marginTop: 10,
            marginRight: 10,
          }}
        >
          <EuiPanel>
            <OrganizationTable
              companySelected={(company) => onCompanySelected(company)}
            />
          </EuiPanel>
        </div>
      ) : (
        <div
          style={{
            marginLeft: 10,
            marginTop: 10,
            marginRight: 10,
            height: "80vh",
          }}
        >
          <EuiPanel
            style={{
              height: "100%",
            }}
          >
            <OrganizationDetails
              company={companySelectedId}
              companyCode={companySelectedCode}
            />
          </EuiPanel>
        </div>
      )}
    </>
  );
};

export default Organizations;
