import React, { Fragment, useState } from "react";
import {
  formatDate,
  EuiInMemoryTable,
  EuiSpacer,
  EuiAvatar,
  EuiBadge,
  EuiButtonIcon,
  EuiLoadingSpinner,
} from "@elastic/eui";
import { useEffect } from "react";
import ExtensionService from "../../services/extenion-service";
import LogsService from "../../services/logs-service";
import axios from "axios";

export const LogsTable = (props) => {
  const [logs, setLogs] = useState([{ name: <EuiLoadingSpinner /> }, {}]);

  const generateSatus = (item) => {
    if (item.result === "Warning") {
      return <EuiBadge color="warning">{item.result}</EuiBadge>;
    }
    if (item.result === "Danger") {
      return <EuiBadge color="danger">{item.result}</EuiBadge>;
    }
    if (item.result === "Success") {
      return <EuiBadge color="success">{item.result}</EuiBadge>;
    }
  };
  useEffect(() => {
    const company = props.data.company;
    const token = sessionStorage.getItem("krakenT");
    const pToken = sessionStorage.getItem("pToken");

    LogsService.logs(token, company)
      .then(async (response) => {
        const logsList = [];
        for (let log of response.data) {
          try {
            const photo = await axios.get(
              `https://graph.microsoft.com/v1.0/users/${log.username}/photo/$value`,
              {
                headers: {
                  Authorization: `Bearer ${pToken}`,
                },
                responseType: "blob",
              }
            );
            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(photo.data);
            logsList.push({
              username: log.username,
              description: log.description,
              result: log.result,
              resultDescription: log.resultDescription,
              ip: log.ip,
              userAgent: log.userAgent,
              createdAt: log.createdAt,
              photo: blobUrl,
            });
          } catch (error) {
            console.log("ERROR CATCHED", error);
          }
        }
        console.log(logsList);
        setLogs(logsList);
      })
      .catch((error) => {
        console.log(error);
      });

    LogsService.logsStatsByMonth(token, company);
  }, []);

  const columns = [
    {
      name: "User",
      render: (item) =>
        item.photo ? (
          <>
            <EuiAvatar size="s" name={item.username} imageUrl={item.photo} />
            &nbsp; {item.username}
          </>
        ) : (
          <>{item.name}</>
        ),
      width: 300,
      truncateText: true,
    },
    {
      field: "description",
      name: "Action",
      sortable: true,
      truncateText: true,
      width: 200,
    },
    {
      name: "Status",
      render: (item) => generateSatus(item),
      truncateText: true,
      width: 100,
    },
    {
      field: "resultDescription",
      name: "Result Description",
      sortable: true,
      truncateText: true,
      width: 500,
    },

    {
      name: "User Agent",
      render: (item) => <>{item.userAgent || ""}</>,
      truncateText: true,
    },
    {
      name: "Src IP",
      render: (item) => <>{item.ip || ""}</>,
      truncateText: true,
    },
    {
      name: "Date",
      render: (item) => formatDate(item.createdAt),
      sortable: true,
      truncateText: true,
    },
  ];

  const search = {
    box: {
      incremental: true,
      schema: true,
    },
  };

  return (
    <Fragment>
      <EuiSpacer size="l" />
      <EuiInMemoryTable
        tableCaption="Extension List"
        items={logs}
        columns={columns}
        search={search}
        pagination={true}
        sorting={true}
      />
    </Fragment>
  );
};
