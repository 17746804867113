import React, { Fragment } from "react";
import {
  EuiIcon,
  EuiTabbedContent,
  EuiText,
  EuiSpacer,
  EuiDescriptionList,
  EuiAvatar,
  EuiFlexGroup,
  EuiCommentList,
  EuiComment,
  EuiCode,
  getColorMode,
  formatDate,
  EuiTitle,
  EuiLoadingSpinner,
  EuiBadge,
  EuiButton,
  EuiButtonIcon,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiCodeBlock,
  EuiModalFooter,
} from "@elastic/eui";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import OrganizationService from "../../services/organization-service";
import { useState } from "react";
import { Extensions } from "../Extensions/Extensions";
import { LogsTable } from "../Logs/LogsTable";
import LogsService from "../../services/logs-service";
import axios from "axios";
import { Chart, BarSeries, Axis } from "@elastic/charts";
import "@elastic/charts/dist/theme_light.css";
import ServicesStatus from "../../services/serviceStatus.service";

export const OrganizationDetails = (props) => {
  const [listItems, setListItems] = useState([]);
  const [logs, setLogs] = useState([]);
  const [logsStats, setLogsStats] = useState([]);
  const [authGraph, setAuthGraph] = useState("");
  const [authGraphDecoded, setAuthGraphDecoded] = useState();
  const [teamsToken, setTeamsToken] = useState("");
  const [teamsTokenDecoded, setTeamsTokenDecoded] = useState();
  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState();
  const [dataModalDecoded, setDataModalDecoded] = useState();

  const getColor = (result) => {
    if (result === "Danger") {
      return "danger";
    }
    if (result === "Warning") {
      return "warning";
    } else {
      return "success";
    }
  };
  const tabs = [
    {
      id: "info",
      name: "General Information",
      content: (
        <Fragment>
          <EuiSpacer />
          <EuiFlexGroup>
            <div
              style={{
                width: "23%",
              }}
            >
              <EuiDescriptionList textStyle="reverse" listItems={listItems} />
            </div>
            <div
              style={{
                marginTop: 10,
                width: "43%",
                marginLeft: -150,
              }}
            >
              <EuiTitle>
                <h2>Last interactions</h2>
              </EuiTitle>
              <EuiSpacer size="xl" />
              {logs.length >= 1 ? (
                <EuiCommentList aria-label="An example with different timeline icons">
                  {logs[0] && logs[0].description === "Refresh HINTS" ? (
                    <EuiComment
                      timelineAvatarAriaLabel={
                        logs[0] && logs[0].name ? logs[0].name : ""
                      }
                      timelineAvatar={
                        <EuiAvatar
                          name="cat"
                          imageUrl={
                            logs[0] && logs[0].photo ? logs[0].photo : ""
                          }
                        />
                      }
                      event={
                        logs[0] && logs[0].description ? (
                          <>
                            <b>REFRESH Hints - {logs[0].createdAt}</b> -{" "}
                            <EuiText
                              size={"s"}
                              color={
                                logs[0] && logs[0].result
                                  ? getColor(logs[0].result)
                                  : "success"
                              }
                            >
                              {" "}
                              {logs[0].resultDescription}
                            </EuiText>
                          </>
                        ) : (
                          ""
                        )
                      }
                    />
                  ) : (
                    logs[0] && (
                      <EuiComment
                        timelineAvatarAriaLabel={
                          logs[0] && logs[0].name ? logs[0].name : ""
                        }
                        timelineAvatar={
                          <EuiAvatar
                            name="cat"
                            imageUrl={
                              logs[0] && logs[0].photo ? logs[0].photo : ""
                            }
                          />
                        }
                        event={
                          logs[0] && logs[0].description ? (
                            <>
                              <b>{logs[0].description}</b> - {logs[0].createdAt}
                            </>
                          ) : (
                            ""
                          )
                        }
                      >
                        <EuiText
                          color={
                            logs[0] && logs[0].result
                              ? getColor(logs[0].result)
                              : "success"
                          }
                          size="s"
                        >
                          <p>
                            {logs[0] && logs[0].resultDescription
                              ? logs[0].resultDescription
                              : ""}
                          </p>
                        </EuiText>
                      </EuiComment>
                    )
                  )}
                  {logs[1] && logs[1].description === "Refresh HINTS" ? (
                    <EuiComment
                      timelineAvatarAriaLabel={
                        logs[1] && logs[1].name ? logs[1].name : ""
                      }
                      timelineAvatar={
                        <EuiAvatar
                          name="cat"
                          imageUrl={
                            logs[1] && logs[1].photo ? logs[1].photo : ""
                          }
                        />
                      }
                      event={
                        logs[1] && logs[1].description ? (
                          <>
                            <b>REFRESH Hints - {logs[1].createdAt}</b> -{" "}
                            <EuiText
                              size={"s"}
                              color={
                                logs[1] && logs[1].result
                                  ? getColor(logs[1].result)
                                  : "success"
                              }
                            >
                              {" "}
                              {logs[1].resultDescription}
                            </EuiText>
                          </>
                        ) : (
                          ""
                        )
                      }
                    />
                  ) : (
                    logs[1] && (
                      <EuiComment
                        timelineAvatarAriaLabel={
                          logs[1] && logs[1].name ? logs[1].name : ""
                        }
                        timelineAvatar={
                          <EuiAvatar
                            name="cat"
                            imageUrl={
                              logs[1] && logs[1].photo ? logs[1].photo : ""
                            }
                          />
                        }
                        event={
                          logs[1] && logs[1].description ? (
                            <>
                              <b>{logs[1].description}</b> - {logs[1].createdAt}
                            </>
                          ) : (
                            ""
                          )
                        }
                      >
                        <EuiText
                          color={
                            logs[1] && logs[1].result
                              ? getColor(logs[1].result)
                              : "success"
                          }
                          size="s"
                        >
                          <p>
                            {logs[1] && logs[1].resultDescription
                              ? logs[1].resultDescription
                              : ""}
                          </p>
                        </EuiText>
                      </EuiComment>
                    )
                  )}
                  {logs[2] && logs[2].description === "Refresh HINTS" ? (
                    <EuiComment
                      timelineAvatarAriaLabel={
                        logs[2] && logs[2].name ? logs[2].name : ""
                      }
                      timelineAvatar={
                        <EuiAvatar
                          name="cat"
                          imageUrl={
                            logs[2] && logs[2].photo ? logs[2].photo : ""
                          }
                        />
                      }
                      event={
                        logs[2] && logs[2].description ? (
                          <>
                            <b>REFRESH Hints - {logs[2].createdAt}</b> -{" "}
                            <EuiText
                              size={"s"}
                              color={
                                logs[2] && logs[2].result
                                  ? getColor(logs[2].result)
                                  : "success"
                              }
                            >
                              {" "}
                              {logs[2].resultDescription}
                            </EuiText>
                          </>
                        ) : (
                          ""
                        )
                      }
                    />
                  ) : (
                    logs[2] && (
                      <EuiComment
                        timelineAvatarAriaLabel={
                          logs[2] && logs[2].name ? logs[2].name : ""
                        }
                        timelineAvatar={
                          <EuiAvatar
                            name="cat"
                            imageUrl={
                              logs[2] && logs[2].photo ? logs[2].photo : ""
                            }
                          />
                        }
                        event={
                          logs[2] && logs[2].description ? (
                            <>
                              <b>{logs[2].description}</b> - {logs[2].createdAt}
                            </>
                          ) : (
                            ""
                          )
                        }
                      >
                        <EuiText
                          color={
                            logs[2] && logs[2].result
                              ? getColor(logs[2].result)
                              : "success"
                          }
                          size="s"
                        >
                          <p>
                            {logs[2] && logs[2].resultDescription
                              ? logs[2].resultDescription
                              : ""}
                          </p>
                        </EuiText>
                      </EuiComment>
                    )
                  )}
                  {logs[3] && logs[3].description === "Refresh HINTS" ? (
                    <EuiComment
                      timelineAvatarAriaLabel={
                        logs[3] && logs[3].name ? logs[3].name : ""
                      }
                      timelineAvatar={
                        <EuiAvatar
                          name="cat"
                          imageUrl={
                            logs[3] && logs[3].photo ? logs[3].photo : ""
                          }
                        />
                      }
                      event={
                        logs[3] && logs[3].description ? (
                          <>
                            <b>REFRESH Hints - {logs[3].createdAt}</b> -{" "}
                            <EuiText
                              size={"s"}
                              color={
                                logs[3] && logs[3].result
                                  ? getColor(logs[3].result)
                                  : "success"
                              }
                            >
                              {" "}
                              {logs[3].resultDescription}
                            </EuiText>
                          </>
                        ) : (
                          ""
                        )
                      }
                    />
                  ) : (
                    logs[3] && (
                      <EuiComment
                        timelineAvatarAriaLabel={
                          logs[3] && logs[3].name ? logs[3].name : ""
                        }
                        timelineAvatar={
                          <EuiAvatar
                            name="cat"
                            imageUrl={
                              logs[3] && logs[3].photo ? logs[3].photo : ""
                            }
                          />
                        }
                        event={
                          logs[3] && logs[3].description ? (
                            <>
                              <b>{logs[3].description}</b> - {logs[3].createdAt}
                            </>
                          ) : (
                            ""
                          )
                        }
                      >
                        <EuiText
                          color={
                            logs[3] && logs[3].result
                              ? getColor(logs[3].result)
                              : "success"
                          }
                          size="s"
                        >
                          <p>
                            {logs[3] && logs[3].resultDescription
                              ? logs[3].resultDescription
                              : ""}
                          </p>
                        </EuiText>
                      </EuiComment>
                    )
                  )}
                  {logs[4] && logs[4].description === "Refresh HINTS" ? (
                    <EuiComment
                      timelineAvatarAriaLabel={
                        logs[4] && logs[4].name ? logs[4].name : ""
                      }
                      timelineAvatar={
                        <EuiAvatar
                          name="cat"
                          imageUrl={
                            logs[4] && logs[4].photo ? logs[4].photo : ""
                          }
                        />
                      }
                      event={
                        logs[4] && logs[4].description ? (
                          <>
                            <b>REFRESH Hints - {logs[4].createdAt}</b> -{" "}
                            <EuiText
                              size={"s"}
                              color={
                                logs[4] && logs[4].result
                                  ? getColor(logs[4].result)
                                  : "success"
                              }
                            >
                              {" "}
                              {logs[4].resultDescription}
                            </EuiText>
                          </>
                        ) : (
                          ""
                        )
                      }
                    />
                  ) : (
                    logs[4] && (
                      <EuiComment
                        timelineAvatarAriaLabel={
                          logs[4] && logs[4].name ? logs[4].name : ""
                        }
                        timelineAvatar={
                          <EuiAvatar
                            name="cat"
                            imageUrl={
                              logs[4] && logs[4].photo ? logs[4].photo : ""
                            }
                          />
                        }
                        event={
                          logs[4] && logs[4].description ? (
                            <>
                              <b>{logs[4].description}</b> - {logs[4].createdAt}
                            </>
                          ) : (
                            ""
                          )
                        }
                      >
                        <EuiText
                          color={
                            logs[4] && logs[4].result
                              ? getColor(logs[4].result)
                              : "success"
                          }
                          size="s"
                        >
                          <p>
                            {logs[4] && logs[4].resultDescription
                              ? logs[4].resultDescription
                              : ""}
                          </p>
                        </EuiText>
                      </EuiComment>
                    )
                  )}
                  {logs[5] && logs[5].description === "Refresh HINTS" ? (
                    <EuiComment
                      timelineAvatarAriaLabel={
                        logs[5] && logs[5].name ? logs[5].name : ""
                      }
                      timelineAvatar={
                        <EuiAvatar
                          name="cat"
                          imageUrl={
                            logs[5] && logs[5].photo ? logs[5].photo : ""
                          }
                        />
                      }
                      event={
                        logs[5] && logs[5].description ? (
                          <>
                            <b>REFRESH Hints - {logs[5].createdAt}</b> -{" "}
                            <EuiText
                              size={"s"}
                              color={
                                logs[5] && logs[5].result
                                  ? getColor(logs[5].result)
                                  : "success"
                              }
                            >
                              {" "}
                              {logs[5].resultDescription}
                            </EuiText>
                          </>
                        ) : (
                          ""
                        )
                      }
                    />
                  ) : (
                    logs[5] && (
                      <EuiComment
                        timelineAvatarAriaLabel={
                          logs[5] && logs[5].name ? logs[5].name : ""
                        }
                        timelineAvatar={
                          <EuiAvatar
                            name="cat"
                            imageUrl={
                              logs[5] && logs[5].photo ? logs[5].photo : ""
                            }
                          />
                        }
                        event={
                          logs[5] && logs[5].description ? (
                            <>
                              <b>{logs[5].description}</b> - {logs[5].createdAt}
                            </>
                          ) : (
                            ""
                          )
                        }
                      >
                        <EuiText
                          color={
                            logs[5] && logs[5].result
                              ? getColor(logs[5].result)
                              : "success"
                          }
                          size="s"
                        >
                          <p>
                            {logs[5] && logs[5].resultDescription
                              ? logs[5].resultDescription
                              : ""}
                          </p>
                        </EuiText>
                      </EuiComment>
                    )
                  )}
                  {logs[6] && logs[6].description === "Refresh HINTS" ? (
                    <EuiComment
                      timelineAvatarAriaLabel={
                        logs[6] && logs[6].name ? logs[6].name : ""
                      }
                      timelineAvatar={
                        <EuiAvatar
                          name="cat"
                          imageUrl={
                            logs[6] && logs[6].photo ? logs[6].photo : ""
                          }
                        />
                      }
                      event={
                        logs[6] && logs[6].description ? (
                          <>
                            <b>REFRESH Hints - {logs[6].createdAt}</b> -{" "}
                            <EuiText
                              size={"s"}
                              color={
                                logs[6] && logs[6].result
                                  ? getColor(logs[6].result)
                                  : "success"
                              }
                            >
                              {" "}
                              {logs[6].resultDescription}
                            </EuiText>
                          </>
                        ) : (
                          ""
                        )
                      }
                    />
                  ) : (
                    logs[6] && (
                      <EuiComment
                        timelineAvatarAriaLabel={
                          logs[6] && logs[6].name ? logs[6].name : ""
                        }
                        timelineAvatar={
                          <EuiAvatar
                            name="cat"
                            imageUrl={
                              logs[6] && logs[6].photo ? logs[6].photo : ""
                            }
                          />
                        }
                        event={
                          logs[6] && logs[6].description ? (
                            <>
                              <b>{logs[6].description}</b> - {logs[6].createdAt}
                            </>
                          ) : (
                            ""
                          )
                        }
                      >
                        <EuiText
                          color={
                            logs[6] && logs[6].result
                              ? getColor(logs[6].result)
                              : "success"
                          }
                          size="s"
                        >
                          <p>
                            {logs[6] && logs[6].resultDescription
                              ? logs[6].resultDescription
                              : ""}
                          </p>
                        </EuiText>
                      </EuiComment>
                    )
                  )}
                </EuiCommentList>
              ) : (
                <EuiCommentList aria-label="An example with different timeline icons">
                  <EuiLoadingSpinner />
                </EuiCommentList>
              )}
            </div>
            <div
              style={{
                marginTop: 10,
                width: "33%",
                marginLeft: 100,
              }}
            >
              <EuiTitle>
                <h2>Service Status</h2>
              </EuiTitle>
              <EuiSpacer size="xxl" />
              <EuiSpacer size="m" />
              <EuiTitle size="xs">
                <h6>Client status</h6>
              </EuiTitle>
              <EuiSpacer size="s" />
              <EuiBadge color="success">active</EuiBadge>
              <EuiSpacer size="xxl" />
              <EuiSpacer size="m" />
              <EuiTitle size="xs">
                <h3>TEAMS Tokens</h3>
              </EuiTitle>
              <EuiSpacer size="s" />
              <EuiText size="s">
                Result: &nbsp;
                {authGraph !== "" && teamsToken !== "" ? (
                  <EuiBadge color="success">working</EuiBadge>
                ) : (
                  <EuiLoadingSpinner />
                )}
              </EuiText>
              <EuiSpacer size="xs" />
              <EuiText size="s">
                User Token:
                {authGraph !== "" && (
                  <EuiButtonIcon
                    onClick={() => {
                      setShowModal(true);
                      setDataModal(teamsToken);
                      setDataModalDecoded(teamsTokenDecoded);
                    }}
                    iconType="eye"
                    aria-label="eye"
                  />
                )}
              </EuiText>
              <EuiSpacer size="xs" />
              <EuiText size="s">
                Graph Token:
                {teamsToken !== "" && (
                  <EuiButtonIcon
                    onClick={() => {
                      setShowModal(true);
                      setDataModal(authGraph);
                      setDataModalDecoded(authGraphDecoded);
                    }}
                    iconType="eye"
                    aria-label="eye"
                  />
                )}
              </EuiText>
              <EuiSpacer size="xxl" />
              <EuiSpacer size="m" />
              <div style={{ widht: "100%", height: "25vh" }}>
                <EuiTitle size="xs">
                  <h3>Last month interactions</h3>
                </EuiTitle>
                <EuiSpacer size="s" />
                {logsStats.length >= 1 ? (
                  <Chart>
                    <BarSeries
                      id="bars"
                      name="Interactions"
                      xScaleType="ordinal"
                      xAccessor="x"
                      yAccessors={["y"]}
                      data={logsStats}
                    />
                    <Axis title={"Day"} id="bottom-axis" position="bottom" />
                    <Axis
                      title={"Interactions"}
                      id="left-axis"
                      position="left"
                      showGridLines
                    />
                  </Chart>
                ) : (
                  <EuiLoadingSpinner />
                )}
              </div>
            </div>
          </EuiFlexGroup>
        </Fragment>
      ),
    },

    {
      id: "users",
      name: (
        <>
          <EuiIcon type="users" />
          &nbsp; Extensions
        </>
      ),
      content: (
        <Fragment>
          <EuiSpacer />
          <Extensions data={props} />
        </Fragment>
      ),
    },
    {
      id: "interactions",
      name: (
        <>
          <EuiIcon type="eye" />
          &nbsp; Interactions
        </>
      ),
      content: (
        <Fragment>
          <EuiSpacer />
          <LogsTable data={props} />
        </Fragment>
      ),
    },
  ];

  useEffect(() => {
    const token = sessionStorage.getItem("krakenT");
    const id = props.company;
    const pToken = sessionStorage.getItem("pToken");

    OrganizationService.getOrganizationInfo(token, id)
      .then((response) => {
        const companyData = response.data.message.data;

        setListItems([
          {
            title: "Name",
            description:
              (
                <>
                  <EuiAvatar
                    size="s"
                    name="Cat"
                    imageUrl={companyData.companyLogo}
                  />
                  &nbsp; {companyData.nameLong}
                </>
              ) || "",
          },
          {
            title: "CIF",
            description: companyData.cif || "",
          },
          {
            title: "Contact Name",
            description: companyData.contact || "",
          },
          {
            title: "Contact Phone",
            description: companyData.phone || "",
          },
          {
            title: "Contact Mail",
            description: companyData.mail || "",
          },
          {
            title: "Company Code",
            description: companyData.companyCode || "",
          },
          {
            title: "Created At",
            description: formatDate(companyData.createdAt) || "",
          },
          {
            title: "Updated At",
            description: formatDate(companyData.updatedAt) || "",
          },
        ]);

        ServicesStatus.teamsTokens(token, id, companyData.mail)
          .then((response) => {
            setAuthGraph(response.data.authGraph.token);
            setAuthGraphDecoded(jwt_decode(response.data.authGraph.token));
            setTeamsToken(response.data.authTeams.token);
            setTeamsTokenDecoded(jwt_decode(response.data.authTeams.token));
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    LogsService.logs(token, id)
      .then(async (response) => {
        const logsList = [];
        for (let log of response.data) {
          try {
            const photo = await axios.get(
              `https://graph.microsoft.com/v1.0/users/${log.username}/photo/$value`,
              {
                headers: {
                  Authorization: `Bearer ${pToken}`,
                },
                responseType: "blob",
              }
            );
            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(photo.data);
            logsList.push({
              username: log.username,
              description: log.description,
              result: log.result,
              resultDescription: log.resultDescription,
              ip: log.ip,
              userAgent: log.userAgent,
              createdAt: formatDate(log.createdAt),
              photo: blobUrl,
            });
          } catch (error) {
            console.log("ERROR CATCHED", error);
          }
        }
        console.log(logsList);
        setLogs(logsList);
      })
      .catch((error) => {
        console.log(error);
      });

    LogsService.logsStatsByMonth(token, id)
      .then((response) => {
        setLogsStats(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <EuiTabbedContent
        tabs={tabs}
        initialSelectedTab={tabs[0]}
        autoFocus="selected"
        onTabClick={(tab) => {
          console.log("clicked tab", tab);
        }}
      />
      {showModal && (
        <EuiModal onClose={() => setShowModal(false)}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>TOKEN Data</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            Token:
            <EuiSpacer size="xs" />
            <EuiCodeBlock fontSize="m" paddingSize="m">
              {dataModal}
            </EuiCodeBlock>
            <EuiSpacer size="xs" />
            Decoded:
            <EuiSpacer size="xs" />
            <EuiCodeBlock language="json" fontSize="m" paddingSize="m">
              {`${JSON.stringify(dataModalDecoded, null, "\t")}`}
            </EuiCodeBlock>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton onClick={() => setShowModal(false)} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </div>
  );
};
