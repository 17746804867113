import { EuiButton } from "@elastic/eui";
import React, { Fragment } from "react";
import { useState } from "react";

import { useEffect } from "react";
import { EditExtension } from "./EditExtension";
import { ExtensionsTable } from "./ExtensionsTable";
import { NewExtension } from "./NewExtension";

export const Extensions = (props) => {
  const [isNewExtensionActive, setIsNewExtensionActive] = useState(false);

  const [isEditExtensionActive, setIsEditExtensionActive] = useState(false);
  const [editData, setEditData] = useState();

  useEffect(() => {}, []);

  return (
    <Fragment>
      <EuiButton onClick={() => setIsNewExtensionActive(true)}>
        {" "}
        New extension{" "}
      </EuiButton>
      {isNewExtensionActive && (
        <NewExtension
          data={props.data}
          closeFlyout={() => setIsNewExtensionActive(false)}
        />
      )}
      {isEditExtensionActive && (
        <EditExtension
          data={props.data}
          closeFlyoutEdit={() => setIsEditExtensionActive(false)}
          editData={editData}
        />
      )}

      <ExtensionsTable
        data={props.data}
        openFlyoutEdit={() => setIsEditExtensionActive(true)}
        setEditData={(data) => setEditData(data)}
      />
    </Fragment>
  );
};
