import React, { useState } from "react";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCollapsibleNavGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiIcon,
  EuiSpacer,
  EuiStepsHorizontal,
  EuiSuperSelect,
  EuiSwitch,
  EuiText,
  EuiTitle,
  useGeneratedHtmlId,
} from "@elastic/eui";
import { SiMicrosoftteams } from "react-icons/si";
import { MdDialerSip } from "react-icons/md";
import { useEffect } from "react";
import ExtensionService from "../../services/extenion-service";
import LogsService from "../../services/logs-service";

export const NewExtension = (props) => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [number, setNumber] = useState("");
  const [extension, setExtension] = useState("");
  const [callerID, setCallerID] = useState("");
  const [checked, setChecked] = useState(false);

  const checkedChange = (e) => {
    console.log(checked);
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  const [firstStatus, setFirstStatus] = useState("disabled");
  const [secondStatus, setSecondStatus] = useState("disabled");
  const [thirdStatus, setThirdStatus] = useState("disabled");
  const [fourthStatus, setFourthStatus] = useState("disabled");

  const optionsSelectPhoneType = [
    {
      value: "directNumber",
      inputDisplay: (
        <>
          <h2>Direct Number</h2>
        </>
      ),
    },
    {
      value: "cliExt",
      inputDisplay: <h2>CLI + EXT</h2>,
      disabled: true,
    },
  ];
  const [valuePhone, setValuePhone] = useState(optionsSelectPhoneType[0].value);
  const onChangeSelect = (value) => {
    setValue(value);
  };
  const onChangeSelectPhone = (value) => {
    setValuePhone(value);
  };
  const complicatedFlyoutTitleId = useGeneratedHtmlId({
    prefix: "complicatedFlyoutTitle",
  });

  const [iconType, setIconType] = useState();
  const [isProcessFinished, setIsProcessFinished] = useState(false);
  const [error, setError] = useState("");
  const [isCloseDisabled, setIsCloseDisabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const [isTryAgainDisabled, setIsTryAgainDisabled] = useState(false);
  const [processFirstTry, setProcessFirstTry] = useState();

  const [isErrored, setIsErrored] = useState(false);

  const horizontalSteps = [
    {
      title: "Data Validation",
      status: firstStatus,
      onClick: () => {},
    },
    {
      title: "MS Provisioning",
      status: secondStatus,
      onClick: () => {},
    },
    {
      title: "Voicehub Provisioning",
      status: thirdStatus,
      onClick: () => {},
    },
    {
      title: "Happiness",
      status: fourthStatus,
      onClick: () => {},
    },
  ];
  const optionsSelect = [
    {
      value: "teams",
      inputDisplay: (
        <>
          <h2>
            <SiMicrosoftteams size={20} /> Teams
          </h2>
        </>
      ),
    },
    {
      value: "sip",
      inputDisplay: (
        <h2>
          <MdDialerSip size={20} /> SIP
        </h2>
      ),
      disabled: true,
    },
    {
      value: "hybrid",
      inputDisplay: (
        <>
          <h2>
            <SiMicrosoftteams size={20} /> Teams + &nbsp;
            <MdDialerSip size={20} /> SIP
          </h2>
        </>
      ),
      disabled: true,
    },
  ];
  const [value, setValue] = useState(optionsSelect[0].value);

  const clickedOnSave = async () => {
    try {
      setIsProcessFinished(false);
      setIsCloseDisabled(true);
      setIsSaveDisabled(true);
      setFirstStatus("disabled");
      setSecondStatus("disabled");
      setThirdStatus("disabled");
      setFourthStatus("disabled");
      const process = await step0();
      setProcessFirstTry(process);
      const admin = await step01(process);
      await step02(admin, process);
      const voicehubStuffs = await step03(admin, process);
      console.log(voicehubStuffs);
      setIsProcessFinished(true);
      setIsCloseDisabled(false);
      setIsSaveDisabled(false);
      setIconType("success");
      setError("User provisioned succesfully");
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      LogsService.create(
        token,
        company,
        "NEW Extension",
        "Success",
        `${mail} - User provisioned succesfully`
      );
    } catch (err) {
      setIsErrored(true);
      setIsCloseDisabled(false);
      setIsSaveDisabled(false);
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      LogsService.create(
        token,
        company,
        "NEW Extension",
        "Danger",
        `ERROR - ${mail} - ${err}`
      );
    }
  };

  const clickedOnTryAgain = async () => {
    try {
      setIsProcessFinished(false);
      setIsCloseDisabled(true);
      setIsTryAgainDisabled(true);
      setFirstStatus("disabled");
      setSecondStatus("disabled");
      setThirdStatus("disabled");
      setFourthStatus("disabled");

      const admin = await step01(processFirstTry);
      await step02(admin, processFirstTry);
      const voicehubStuffs = await step03(admin, processFirstTry);
      console.log(voicehubStuffs);
      setIsProcessFinished(true);
      setIsCloseDisabled(false);
      setIsTryAgainDisabled(false);
      setIconType("success");
      setError("User provisioned succesfully");
      setIsErrored(false);
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      LogsService.create(
        token,
        company,
        "NEW Extension",
        "Success",
        `${mail} - User provisioned succesfully`
      );
    } catch (err) {
      setIsErrored(true);
      setIsCloseDisabled(false);
      setIsTryAgainDisabled(false);
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      LogsService.create(
        token,
        company,
        "NEW Extension",
        "Danger",
        `ERROR - ${mail} - ${err}`
      );
    }
  };

  const step0 = async () => {
    try {
      setFirstStatus("current");
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      const newProcess = await ExtensionService.newProcess(token, company);
      const companyCode = props.data.companyCode;
      const bodyToPost = {
        name: name,
        mail: mail,
        userType: value,
        extension: extension,
        teamsPhone: number,
        companyCode: companyCode,
      };
      console.log(bodyToPost);
      const response = await ExtensionService.newExtension(
        token,
        newProcess.data.message._id,
        company,
        bodyToPost
      );
      console.log(response);
      return newProcess.data.message._id;
    } catch (error) {
      console.log(error);
      setFirstStatus("danger");
      setIconType("danger");
      setIsProcessFinished(true);
      setError(
        "Some error ocurred while performing this action, please, check form data or contact with dev@astroline.com"
      );
      throw new Error(
        "Some error ocurred while performing this action, please, check form data or contact with dev@astroline.com"
      );
    }
  };

  const step01 = async (process) => {
    try {
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      const processId = process;
      const response = await ExtensionService.dataValidation(
        token,
        processId,
        company
      );
      setFirstStatus("complete");
      setSecondStatus("current");
      return response.data;
    } catch (error) {
      setFirstStatus("danger");
      setIconType("danger");
      setIsProcessFinished(true);
      setError(
        "Some error ocurred while performing this action, please, contact with dev@astroline.com"
      );
      throw new Error(
        "Some error ocurred while performing this action, please, contact with dev@astroline.com"
      );
    }
  };

  const step02 = async (admin, process) => {
    try {
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      const processId = process;
      const companyCode = props.data.companyCode;
      const bodyToPost = {
        name: name,
        mail: mail,
        userType: value,
        extension: extension,
        teamsPhone: number,
        companyCode: companyCode,
      };
      console.log(bodyToPost);
      const response = await ExtensionService.msStuffs(
        admin,
        token,
        processId,
        company,
        bodyToPost
      );

      setSecondStatus("complete");
      setThirdStatus("current");
      return response.data;
    } catch (error) {
      setSecondStatus("danger");
      setIconType("danger");
      setIsProcessFinished(true);
      setError(
        "Some error ocurred while provisioning LineUri on Teams, probably user does not exist or lacks enough licenses"
      );

      throw new Error(
        "Some error ocurred while provisioning LineUri on Teams, probably user does not exist or lacks enough licenses"
      );
    }
  };

  const step03 = async (admin, process) => {
    try {
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      const processId = process;
      const companyCode = props.data.companyCode;
      const bodyToPost = {
        name: name,
        mail: mail,
        userType: value,
        extension: extension,
        teamsPhone: number,
        companyCode: companyCode,
        callerID: callerID,
        inboundRoute: checked,
      };
      console.log(bodyToPost);
      console.log(props);
      const response = await ExtensionService.voiceHubStuffs(
        admin,
        token,
        processId,
        company,
        bodyToPost
      );

      setThirdStatus("complete");
      setFourthStatus("complete");
      return response.data;
    } catch (error) {
      const errorData = error.response.data.message;
      setThirdStatus("danger");
      setIconType("danger");
      setIsProcessFinished(true);
      setError(errorData);

      throw new Error(errorData);
    }
  };

  useEffect(() => {}, []);

  return (
    <div>
      <EuiFlyout
        ownFocus
        onClose={() => props.closeFlyout()}
        hideCloseButton
        aria-labelledby={complicatedFlyoutTitleId}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id={complicatedFlyoutTitleId}>
              <EuiIcon type="plusInCircleFilled" size="xl" />
              &nbsp; Add new extension
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="Name"
                helpText="Example: 'Jhon Doe'"
                display="rowCompressed"
              >
                <EuiFieldText
                  name="first"
                  compressed
                  onChange={(e) => setName(e.target.value)}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="Mail"
                helpText="Exmple: jhon@contoso.com"
                display="rowCompressed"
              >
                <EuiFieldText
                  name="first"
                  onChange={(e) => setMail(e.target.value)}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="m" />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow label="Type" display="rowCompressed">
                <EuiSuperSelect
                  options={optionsSelect}
                  valueOfSelected={value}
                  onChange={(value) => onChangeSelect(value)}
                />
              </EuiFormRow>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiFormRow label="MS - Number Structure" display="rowCompressed">
                <EuiSuperSelect
                  options={optionsSelectPhoneType}
                  valueOfSelected={valuePhone}
                  onChange={(value) => onChangeSelectPhone(value)}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="Number"
                helpText="Example: +34917371000"
                display="rowCompressed"
              >
                <EuiFieldText
                  name="first"
                  onChange={(e) => setNumber(e.target.value)}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="Extension"
                helpText="Example: 100"
                display="rowCompressed"
              >
                <EuiFieldText
                  name="first"
                  onChange={(e) => setExtension(e.target.value)}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="CallerID"
                helpText="Example: +34917371000"
                display="rowCompressed"
              >
                <EuiFieldText
                  name="first"
                  onChange={(e) => setCallerID(e.target.value)}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow display="rowCompressed">
                <EuiSwitch
                  label="Create 24/7 inbound"
                  checked={checked}
                  onChange={(e) => checkedChange(e)}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="xxl" />
          <EuiSpacer size="xxl" />
          <EuiSpacer size="xxl" />
          <EuiStepsHorizontal steps={horizontalSteps} />
          <EuiSpacer size="xxl" />
          {isProcessFinished && (
            <EuiCollapsibleNavGroup
              title="Result"
              iconProps={{ color: iconType }}
              iconType="gisApp"
              iconSize="xxl"
              titleSize="s"
              isCollapsible={true}
              initialIsOpen={false}
              background="light"
            >
              <EuiText size="s">
                <p>{error}</p>
              </EuiText>
            </EuiCollapsibleNavGroup>
          )}
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="cross"
                onClick={() => props.closeFlyout()}
                flush="left"
                isDisabled={isCloseDisabled}
              >
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {isErrored ? (
                <EuiButton
                  onClick={clickedOnTryAgain}
                  isDisabled={isTryAgainDisabled}
                  isLoading={isTryAgainDisabled}
                  fill
                >
                  Try to fix
                </EuiButton>
              ) : (
                <EuiButton
                  onClick={clickedOnSave}
                  isDisabled={isSaveDisabled}
                  isLoading={isSaveDisabled}
                  fill
                >
                  Save
                </EuiButton>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </div>
  );
};
