import { EuiButton } from "@elastic/eui";
import "@elastic/eui/dist/eui_theme_light.css";
import React, { useState, Fragment, useEffect } from "react";
import Organizations from "../components/Organizations/Organizations";

const RenderPage = (props) => {
  const [selectedPage, setSelectedPage] = useState("light");

  useEffect(() => {
    setSelectedPage(props.page);
  }, [props]);
  return (
    <>
      {selectedPage === "new" && (
        <div style={{ height: "80vh" }}>
          <div style={{ height: "88vh" }}>new</div>
          <div>
            <EuiButton
              onClick={() => {
                props.deleteSelected();
              }}
            >
              Back to Home
            </EuiButton>
          </div>
        </div>
      )}
      {selectedPage === "billing" && (
        <div style={{ height: "80vh" }}>
          <div style={{ height: "88vh" }}>billing</div>
          <div>
            <EuiButton
              onClick={() => {
                props.deleteSelected();
              }}
            >
              Back to Home
            </EuiButton>
          </div>
        </div>
      )}
      {selectedPage === "management" && (
        <div style={{ height: "80vh" }}>
          <div style={{ height: "88vh" }}>
            <Organizations />
          </div>
          <div>
            <EuiButton
              onClick={() => {
                props.deleteSelected();
              }}
            >
              Back to Home
            </EuiButton>
          </div>
        </div>
      )}
      {selectedPage === "users" && (
        <div style={{ height: "80vh" }}>
          <div style={{ height: "88vh" }}>users</div>
          <div>
            <EuiButton
              onClick={() => {
                props.deleteSelected();
              }}
            >
              Back to Home
            </EuiButton>
          </div>
        </div>
      )}
    </>
  );
};

export default RenderPage;
