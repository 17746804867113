import React, { useState } from "react";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiCallOut,
  EuiCodeBlock,
  EuiCollapsibleNavGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiStepsHorizontal,
  EuiSuperSelect,
  EuiSwitch,
  EuiText,
  EuiTitle,
  useGeneratedHtmlId,
} from "@elastic/eui";
import { SiMicrosoftteams } from "react-icons/si";
import { MdDialerSip } from "react-icons/md";
import { useEffect } from "react";
import ExtensionService from "../../services/extenion-service";
import LogsService from "../../services/logs-service";

export const EditExtension = (props) => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [number, setNumber] = useState("");
  const [extension, setExtension] = useState("");
  const [callerID, setCallerID] = useState("");
  const [checked, setChecked] = useState(false);

  const checkedChange = (e) => {
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  const [firstStatus, setFirstStatus] = useState("disabled");
  const [secondStatus, setSecondStatus] = useState("disabled");
  const [thirdStatus, setThirdStatus] = useState("disabled");
  const [fourthStatus, setFourthStatus] = useState("disabled");

  const [isErrored, setIsErrored] = useState(false);

  const horizontalSteps = [
    {
      title: "Data Validation",
      status: firstStatus,
      onClick: () => {},
    },
    {
      title: "MS Provisioning",
      status: secondStatus,
      onClick: () => {},
    },
    {
      title: "Voicehub Provisioning",
      status: thirdStatus,
      onClick: () => {},
    },
    {
      title: "Happiness",
      status: fourthStatus,
      onClick: () => {},
    },
  ];
  const optionsSelect = [
    {
      value: "teams",
      inputDisplay: (
        <>
          <h2>
            <SiMicrosoftteams size={20} /> Teams
          </h2>
        </>
      ),
    },
    {
      value: "sip",
      inputDisplay: (
        <h2>
          <MdDialerSip size={20} /> SIP
        </h2>
      ),
      disabled: true,
    },
    {
      value: "hybrid",
      inputDisplay: (
        <>
          <h2>
            <SiMicrosoftteams size={20} /> Teams + &nbsp;
            <MdDialerSip size={20} /> SIP
          </h2>
        </>
      ),
      disabled: true,
    },
  ];

  const optionsSelectPhoneType = [
    {
      value: "directNumber",
      inputDisplay: (
        <>
          <h2>Direct Number</h2>
        </>
      ),
    },
    {
      value: "cliExt",
      inputDisplay: <h2>CLI + EXT</h2>,
      disabled: true,
    },
  ];
  const [value, setValue] = useState(optionsSelect[0].value);
  const [valuePhone, setValuePhone] = useState(optionsSelectPhoneType[0].value);
  const onChangeSelect = (value) => {
    setValue(value);
  };
  const onChangeSelectPhone = (value) => {
    setValuePhone(value);
  };
  const complicatedFlyoutTitleId = useGeneratedHtmlId({
    prefix: "complicatedFlyoutTitle",
  });

  const [iconType, setIconType] = useState();
  const [isProcessFinished, setIsProcessFinished] = useState(false);
  const [error, setError] = useState(false);
  const [isCloseDisabled, setIsCloseDisabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const [isTryAgainDisabled, setIsTryAgainDisabled] = useState(false);
  const [processFirstTry, setProcessFirstTry] = useState();

  const clickedOnSave = async () => {
    try {
      setIsProcessFinished(false);
      setIsCloseDisabled(true);
      setIsSaveDisabled(true);
      setFirstStatus("disabled");
      setSecondStatus("disabled");
      setThirdStatus("disabled");
      setFourthStatus("disabled");
      const admin = await step01(processFirstTry);
      const step02result = await step02(admin, processFirstTry);
      console.log(step02result);
      const step03result = await step03(admin, processFirstTry);
      console.log(step03result);
      setIsProcessFinished(true);
      setIsCloseDisabled(false);
      setIsSaveDisabled(false);
      setIconType("success");
      setError("User provisioned succesfully");
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      LogsService.create(
        token,
        company,
        "UPDATE Extension",
        "Success",
        `User provisioned succesfully`
      );
    } catch (err) {
      console.log("ERROR EN SAVE", error);
      setIsErrored(true);
      setIsCloseDisabled(false);
      setIsSaveDisabled(false);
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      LogsService.create(
        token,
        company,
        "UPDATE Extension",
        "Danger",
        `ERROR - ${error}`
      );
    }
  };

  const clickedOnTryAgain = async () => {
    try {
      setIsProcessFinished(false);
      setIsCloseDisabled(true);
      setIsTryAgainDisabled(true);
      setFirstStatus("disabled");
      setSecondStatus("disabled");
      setThirdStatus("disabled");
      setFourthStatus("disabled");

      const admin = await step01(processFirstTry);
      const step02result = await step02(admin, processFirstTry);
      console.log(step02result);
      const step03result = await step03(admin, processFirstTry);
      console.log(step03result);

      setIsProcessFinished(true);
      setIsCloseDisabled(false);
      setIsTryAgainDisabled(false);
      setIconType("success");
      setError("User provisioned succesfully");
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      LogsService.create(
        token,
        company,
        "UPDATE Extension",
        "Success",
        `User provisioned succesfully`
      );
      setIsErrored(false);
    } catch (err) {
      console.log("ERROR EN TRY AGAIN", error);
      setIsErrored(true);
      setIsCloseDisabled(false);
      setIsTryAgainDisabled(false);
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      LogsService.create(
        token,
        company,
        "UPDATE Extension",
        "Danger",
        `ERROR - ${error}`
      );
    }
  };

  const step01 = async (process) => {
    try {
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      const processId = process;
      const response = await ExtensionService.dataValidation(
        token,
        processId,
        company
      );
      setFirstStatus("complete");
      setSecondStatus("current");
      return response.data;
    } catch (error) {
      setFirstStatus("danger");
      setIconType("danger");
      setIsProcessFinished(true);
      setError(`${mail} - DATA Validation failed`);

      throw new Error("ERROR");
    }
  };

  const step02 = async (admin, process) => {
    try {
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      const processId = process;
      const companyCode = props.data.companyCode;
      const bodyToPost = {
        name: name,
        mail: mail,
        userType: value,
        extension: extension,
        teamsPhone: number,
        companyCode: companyCode,
        callerID: callerID,
        inboundRoute: checked,
      };
      const response = await ExtensionService.msStuffs(
        admin,
        token,
        processId,
        company,
        bodyToPost
      );

      setSecondStatus("complete");
      setThirdStatus("current");
      return response.data;
    } catch (error) {
      setSecondStatus("danger");
      setIconType("danger");
      setIsProcessFinished(true);
      setError(`${mail} - MS Provision failed`);

      throw new Error("ERROR");
    }
  };

  const step03 = async (admin, process) => {
    try {
      const company = props.data.company;
      const token = sessionStorage.getItem("krakenT");
      const processId = process;
      const companyCode = props.data.companyCode;
      const bodyToPost = {
        name: name,
        mail: mail,
        userType: value,
        extension: extension,
        teamsPhone: number,
        companyCode: companyCode,
      };

      const response = await ExtensionService.voiceHubStuffs(
        admin,
        token,
        processId,
        company,
        bodyToPost
      );

      setThirdStatus("complete");
      setFourthStatus("complete");
      return response.data;
    } catch (error) {
      setThirdStatus("danger");
      setIconType("danger");
      setIsProcessFinished(true);
      setError(`${mail} - ${error.response.data.message}`);
      throw new Error("ERROR");
    }
  };

  useEffect(() => {
    const data = props.editData;
    setName(data.name);
    setMail(data.mail);
    setNumber(data.teamsPhone);
    setExtension(data.extension);
    setProcessFirstTry(data.transactionId);
    const token = sessionStorage.getItem("krakenT");
    ExtensionService.getProcess(token, data.transactionId)
      .then((response) => {
        const processList = response.data.message.processDescription;
        const length = response.data.message.processDescription.length;
        const stepActual = processList[length - 1];
        if (stepActual.step === 0 && stepActual.status === "error") {
          setFirstStatus("danger");
          setSecondStatus("disabled");
          setThirdStatus("disabled");
          setFourthStatus("disabled");
          setIconType("danger");
          setIsProcessFinished(true);
          setError(data.mail + " " + "-" + " " + stepActual.description);
        } else if (stepActual.step === 1 && stepActual.status === "error") {
          setFirstStatus("complete");
          setSecondStatus("danger");
          setThirdStatus("disabled");
          setFourthStatus("disabled");
          setIconType("danger");
          setIsProcessFinished(true);
          setError(mail + " " + "-" + " " + stepActual.description);
        } else if (stepActual.step === 2 && stepActual.status === "error") {
          setFirstStatus("complete");
          setSecondStatus("complete");
          setThirdStatus("danger");
          setFourthStatus("disabled");
          setIconType("danger");
          setIsProcessFinished(true);
          setError(mail + " " + "-" + " " + stepActual.description);
        } else {
          setFirstStatus("complete");
          setSecondStatus("complete");
          setThirdStatus("complete");
          setFourthStatus("complete");
          setIconType("success");
          setIsProcessFinished(true);
          setError("User provisioned succesfully");
        }
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [queuesData, setQueuesData] = useState([]);

  const closeModal = () => setIsModalVisible(false);

  const clickedRefreshHints = () => {
    setIsModalVisible(true);
    const token = sessionStorage.getItem("krakenT");
    const extension = `ext${props.data.companyCode}${props.editData.extension}`;

    ExtensionService.getQueuesFromExtension(token, extension)
      .then((response) => {
        const dataToPush = [];
        for (let data of response.data.message.data) {
          dataToPush.push(data.queue_name);
        }

        if (dataToPush.length === 0) {
          setQueuesData(["none"]);
        }
        setQueuesData(dataToPush);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [isRefreshConfirmLoading, setIsRefreshConfirmLoading] = useState(false);

  const [refreshConfirmResponseVisible, setRefreshConfirmResponseVisible] =
    useState();
  const [refreshConfirmResponse, setRefreshConfirmResponse] = useState();

  const clickedConfirmRefresh = () => {
    const token = sessionStorage.getItem("krakenT");
    const company = props.data.company;
    const extension = `ext${props.data.companyCode}${props.editData.extension}`;
    const teamsID = props.editData.teamsID;
    setIsRefreshConfirmLoading(true);
    setRefreshConfirmResponseVisible(false);

    ExtensionService.refreshHints(token, extension, teamsID)
      .then((response) => {
        const rows = response.data.message.data.changedRows;
        if (rows === 0) {
          setRefreshConfirmResponse({
            data: "Dont waste your time! Hints were already refreshed",
            color: "warning",
            icon: "faceNeutral",
          });
          setRefreshConfirmResponseVisible(true);
          setIsRefreshConfirmLoading(false);
          LogsService.create(
            token,
            company,
            "Refresh HINTS",
            "Warning",
            `${props.editData.mail} - Already refreshed`
          );
        } else {
          setRefreshConfirmResponse({
            data: "Hints refreshed succesfully",
            color: "success",
            icon: "faceHappy",
          });
          setRefreshConfirmResponseVisible(true);
          setIsRefreshConfirmLoading(false);
          LogsService.create(
            token,
            company,
            "Refresh HINTS",
            "Success",
            `${props.editData.mail} - Refreshed succesfully`
          );
        }
      })
      .catch((error) => {
        setRefreshConfirmResponse({
          data: "Some error ocurred while refreshing hints",
          color: "danger",
          icon: "faceSad",
        });
        setRefreshConfirmResponseVisible(true);
        setIsRefreshConfirmLoading(false);
      });
  };

  return (
    <div>
      <EuiFlyout
        ownFocus
        onClose={() => props.closeFlyoutEdit()}
        hideCloseButton
        aria-labelledby={complicatedFlyoutTitleId}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id={complicatedFlyoutTitleId}>
              <EuiIcon type="documentEdit" size="xl" />
              &nbsp; Edit extension
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="Name"
                helpText="Example: 'Jhon Doe'"
                display="rowCompressed"
              >
                <EuiFieldText
                  required
                  value={name}
                  name="first"
                  compressed
                  onChange={(e) => setName(e.target.value)}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="Mail"
                helpText="Exmple: jhon@contoso.com"
                display="rowCompressed"
              >
                <EuiFieldText
                  name="first"
                  required
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="m" />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow label="Type" display="rowCompressed">
                <EuiSuperSelect
                  options={optionsSelect}
                  valueOfSelected={value}
                  onChange={(value) => onChangeSelect(value)}
                />
              </EuiFormRow>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiFormRow label="MS - Number Structure" display="rowCompressed">
                <EuiSuperSelect
                  options={optionsSelectPhoneType}
                  valueOfSelected={valuePhone}
                  onChange={(value) => onChangeSelectPhone(value)}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="Number"
                helpText="Example: +34917371000"
                display="rowCompressed"
              >
                <EuiFieldText
                  name="first"
                  value={number}
                  required
                  onChange={(e) => setNumber(e.target.value)}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="Extension"
                helpText="Example: 100"
                display="rowCompressed"
              >
                <EuiFieldText
                  name="first"
                  required
                  onChange={(e) => setExtension(e.target.value)}
                  value={extension}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="CallerID"
                helpText="Example: +34917371000"
                display="rowCompressed"
              >
                <EuiFieldText
                  name="first"
                  onChange={(e) => setCallerID(e.target.value)}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow display="rowCompressed">
                <EuiSwitch
                  label="Create 24/7 inbound"
                  checked={checked}
                  onChange={(e) => checkedChange(e)}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="xxl" />
          {fourthStatus === "complete" && (
            <>
              <b>Refresh Hints</b>
              <EuiSpacer size="s" />
              <EuiFlexGroup
                responsive={false}
                gutterSize="s"
                alignItems="center"
              >
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    onClick={() => clickedRefreshHints()}
                    aria-labelledby="refresh hints"
                    contentProps={{
                      label: "Refresh Hints",
                      description: "Refresh Hints",
                    }}
                    label="Refresh hints"
                    iconType="refresh"
                    aria-label="refresh hints"
                    color="primary"
                  >
                    {" "}
                    Refresh Hints
                  </EuiButtonIcon>
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          )}
          <EuiSpacer size="xxl" />
          <EuiSpacer size="xxl" />
          <EuiStepsHorizontal steps={horizontalSteps} />
          <EuiSpacer size="xxl" />
          <EuiSpacer size="xxl" />

          {isProcessFinished && (
            <EuiCollapsibleNavGroup
              title="Result"
              iconProps={{ color: iconType }}
              iconType="gisApp"
              iconSize="xxl"
              titleSize="s"
              isCollapsible={true}
              initialIsOpen={false}
              background="light"
            >
              <EuiText size="s">
                <p>{error}</p>
              </EuiText>
            </EuiCollapsibleNavGroup>
          )}
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="cross"
                onClick={() => props.closeFlyoutEdit()}
                flush="left"
                isDisabled={isCloseDisabled}
              >
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {isErrored ? (
                <EuiButton
                  onClick={clickedOnTryAgain}
                  isDisabled={isTryAgainDisabled}
                  isLoading={isTryAgainDisabled}
                  fill
                >
                  Try to fix
                </EuiButton>
              ) : (
                <EuiButton
                  onClick={clickedOnSave}
                  isDisabled={isSaveDisabled}
                  isLoading={isSaveDisabled}
                  fill
                >
                  Save
                </EuiButton>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
      {isModalVisible && (
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Please confirm before refresh</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            User seems to be included in following queues:
            <EuiSpacer />
            <EuiCodeBlock language="html" isCopyable>
              {queuesData.length !== 0 && (
                <>
                  {queuesData.map((queue) => (
                    <>
                      {queue}
                      <br></br>
                    </>
                  ))}
                </>
              )}
            </EuiCodeBlock>
            <EuiSpacer size="l" />
            {refreshConfirmResponseVisible && (
              <>
                {" "}
                <EuiCallOut
                  title={refreshConfirmResponse.data}
                  iconType={refreshConfirmResponse.icon}
                  color={refreshConfirmResponse.color}
                ></EuiCallOut>
              </>
            )}
          </EuiModalBody>

          <EuiModalFooter>
            {queuesData.length !== 0 ? (
              <>
                <EuiButton
                  onClick={() => {
                    clickedConfirmRefresh();
                  }}
                  fill
                  disabled={isRefreshConfirmLoading}
                  isLoading={isRefreshConfirmLoading}
                >
                  Refresh
                </EuiButton>{" "}
              </>
            ) : (
              <>
                {" "}
                <EuiButton
                  disabled
                  onClick={() => {
                    clickedConfirmRefresh();
                  }}
                  fill
                >
                  Impossible to refresh the unrefreshable
                </EuiButton>
              </>
            )}
          </EuiModalFooter>
        </EuiModal>
      )}
    </div>
  );
};
