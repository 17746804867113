import http from "../http-common";

const getOrganizations = (token) => {
  return http.get(`/api/v1/organization/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getOrganizationInfo = (token, id) => {
  return http.get(`/api/v1/organization/id?id=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const OrganizationService = {
  getOrganizations,
  getOrganizationInfo,
};

export default OrganizationService;
