import http from "../http-common";

const logs = async (token, companyId) => {
  return http.get(`/api/v1/logger/getByCompanyId?id=${companyId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const create = async (
  token,
  companyId,
  description,
  result,
  resultDescription
) => {
  return http.post(
    `/api/v1/logger/create?companyId=${companyId}&description=${description}&result=${result}&resultDescription=${resultDescription}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const logsStatsByMonth = async (token, companyId) => {
  return http.get(`/api/v1/logger/getByCompanyId/lastMonth?id=${companyId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
const LogsService = {
  logs,
  create,
  logsStatsByMonth,
};

export default LogsService;
