import React, { useState } from "react";
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiIcon,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiPopover,
  EuiSpacer,
  EuiText,
  useGeneratedHtmlId,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiButton,
  EuiFormRow,
  EuiFieldText,
  EuiForm,
  EuiFilePicker,
} from "@elastic/eui";
import LoginService from "../services/login-service";
import { useNavigate } from "react-router-dom";

const HeaderUserMenu = () => {
  const userPopoverId = useGeneratedHtmlId({ prefix: "userPopover" });
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={userPopoverId}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={onMenuButtonClick}
    >
      <EuiAvatar
        name={localStorage.getItem("UN") || ""}
        size="s"
        imageUrl={sessionStorage.getItem("photoUser")}
      />
    </EuiHeaderSectionItemButton>
  );

  const logout = () => {
    LoginService.logout();
    navigate("/login");
  };

  const [modal, showModal] = useState(false);

  const changePhoto = () => {
    console.log("Clicked on change Photo");
    showModal(true);
  };

  const closeModal = () => {
    showModal(false);
  };

  const [filesData, setFiles] = useState({});

  const onChange = (files) => {
    setFiles(files.length > 0 ? Array.from(files) : []);
  };

  const save = () => {
    console.log(filesData);
  };
  return (
    <EuiPopover
      id={userPopoverId}
      repositionOnScroll
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
      panelPaddingSize="none"
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup
          gutterSize="m"
          className="euiHeaderProfile"
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            <EuiAvatar
              name={localStorage.getItem("UN") || ""}
              size="xl"
              imageUrl={sessionStorage.getItem("photoUser")}
            />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>{localStorage.getItem("UN") || ""}</p>
            </EuiText>

            <EuiSpacer size="m" />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent="spaceBetween">
                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={changePhoto}>Edit profile</EuiLink>
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={logout}>Log out</EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        {modal && (
          <>
            {" "}
            <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  <h1>Choose photo file</h1>
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                {" "}
                <EuiForm component="form">
                  <EuiFilePicker
                    multiple
                    initialPromptText="Select or drag photo file"
                    onChange={onChange}
                    display="default"
                    aria-label="Use aria labels when no actual label is in use"
                  />
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>

                <EuiButton type="submit" onClick={save} fill>
                  Save
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </>
        )}
      </div>
    </EuiPopover>
  );
};

const Header = () => {
  const [position] = useState("static");

  return (
    <>
      <EuiHeader position={position} theme={"default"}>
        <EuiHeaderSection grow={false}>
          <EuiHeaderSectionItem border="right">
            <EuiIcon
              type={
                "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/d8nhcdj3fhsrlg1xcrk1"
              }
              size="xl"
              title="SVGLogo"
            />
            &nbsp;&nbsp; <b>Welcome {localStorage.getItem("UN")}!</b>
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
        <EuiHeaderSection side="right">
          {/* <EuiHeaderSectionItem>
            <HeaderUpdates username={localStorage.getItem("UN")} />
          </EuiHeaderSectionItem> */}
          <EuiHeaderSectionItem>
            <HeaderUserMenu />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
    </>
  );
};

export default Header;
