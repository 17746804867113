import React, { Fragment, useState } from "react";
import {
  formatDate,
  EuiInMemoryTable,
  EuiSpacer,
  EuiAvatar,
  EuiBadge,
  EuiButton,
  EuiFlexGrid,
  EuiButtonIcon,
  EuiText,
} from "@elastic/eui";
import { useEffect } from "react";
import ExtensionService from "../../services/extenion-service";

export const ExtensionsTable = (props) => {
  const [extensions, setExtensions] = useState([]);

  useEffect(() => {
    const company = props.data.company;
    const token = sessionStorage.getItem("krakenT");
    ExtensionService.getExtensionsByCompanyId(token, company)
      .then((response) => {
        const extensionsList = [];
        for (let extension of response.data.message.data) {
          extensionsList.push(extension);
        }
        setExtensions(extensionsList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const extensionClicked = (item) => {
    console.log(item);
    props.setEditData(item);
    props.openFlyoutEdit();
  };

  const generateSatus = (item) => {
    console.log(item);
    if (item.status === "Provisioning") {
      return <EuiBadge color="warning">{item.status}</EuiBadge>;
    }
    if (item.status === "Provision Failed") {
      return (
        <>
          <EuiBadge color="danger">{item.status}</EuiBadge> &nbsp;{" "}
          <EuiButtonIcon
            onClick={() => extensionClicked(item)}
            iconType="refresh"
            aria-label="Heart"
            color="accent"
            aria-labelledby="Click me to fix"
          />
        </>
      );
    }
    if (item.status === "Success") {
      return <EuiBadge color="success">{item.status}</EuiBadge>;
    }
  };
  const actions = [
    {
      name: "Edit",
      description: "Click here to edit user",
      icon: "documentEdit",
      type: "icon",
      onClick: (item) => extensionClicked(item),
    },
  ];

  const columns = [
    {
      name: "",
      actions,
      width: 30,
    },
    {
      field: "name",
      name: "Name",
      sortable: true,
      truncateText: true,
    },
    {
      field: "mail",
      name: "EMAIL",
      sortable: true,
      truncateText: true,
    },
    {
      field: "userType",
      name: "TYPE",
      sortable: true,
      truncateText: true,
    },
    {
      field: "teamsPhone",
      name: "Teams Phone",
      sortable: true,
      truncateText: true,
    },

    {
      field: "extension",
      name: "Extension",
      sortable: true,
      truncateText: true,
    },
    {
      field: "teamsID",
      name: "TEAMS ID",
      sortable: true,
      truncateText: true,
    },
    {
      field: "userCreator",
      name: "Created By",
      sortable: true,
      truncateText: true,
    },

    {
      field: "createdAt",
      name: "Date Creation",
      dataType: "date",
      render: (date) => formatDate(date),
      sortable: true,
      truncateText: true,
    },
    {
      field: "updatedAt",
      name: "Last Update",
      dataType: "date",
      render: (date) => formatDate(date),
      sortable: true,
      truncateText: true,
    },
    {
      name: "Status",
      render: (item) => generateSatus(item),
      truncateText: true,
    },
  ];

  const search = {
    box: {
      incremental: true,
      schema: true,
    },
  };

  return (
    <Fragment>
      <EuiSpacer size="l" />
      <EuiInMemoryTable
        tableCaption="Extension List"
        items={extensions}
        columns={columns}
        search={search}
        pagination={true}
        sorting={true}
      />
    </Fragment>
  );
};
