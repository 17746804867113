import React from "react";
import {
  EuiFlexGroup,
  EuiIcon,
  EuiKeyPadMenu,
  EuiKeyPadMenuItem,
  EuiPanel,
} from "@elastic/eui";
import { useState } from "react";
import RenderPage from "../../pages/renderPage";

const HomeComponent = () => {
  const [selected, setSelected] = useState();

  return (
    <>
      {selected !== undefined ? (
        <RenderPage
          page={selected}
          deleteSelected={() => setSelected(undefined)}
        />
      ) : (
        <div
          style={{
            width: "100%",
          }}
        >
          <nav aria-label="Nav title">
            <EuiKeyPadMenu
              style={{
                width: "100%",
                marginTop: "10%",
                placeContent: "center",
              }}
            >
              <EuiPanel>
                <EuiFlexGroup>
                  <div>
                    <EuiKeyPadMenuItem
                      label="New entries"
                      style={{ width: 200, height: 200 }}
                      onClick={() => setSelected("new")}
                    >
                      <EuiIcon type="createSingleMetricJob" size="xxl" />
                    </EuiKeyPadMenuItem>
                    <EuiKeyPadMenuItem
                      label="Billing"
                      style={{ width: 200, height: 200 }}
                      onClick={() => setSelected("billing")}
                    >
                      <EuiIcon type="visualizeApp" size="xxl" />
                    </EuiKeyPadMenuItem>
                  </div>
                  <div>
                    <EuiKeyPadMenuItem
                      label="Customer Management"
                      style={{ width: 200, height: 200 }}
                      onClick={() => setSelected("management")}
                    >
                      <EuiIcon type="indexRollupApp" size="xxl" />
                    </EuiKeyPadMenuItem>
                    <EuiKeyPadMenuItem
                      label="User management"
                      style={{ width: 200, height: 200 }}
                      onClick={() => setSelected("users")}
                    >
                      <EuiIcon type="managementApp" size="xxl" />
                    </EuiKeyPadMenuItem>
                  </div>
                </EuiFlexGroup>
              </EuiPanel>
            </EuiKeyPadMenu>
          </nav>
        </div>
      )}
    </>
  );
};

export default HomeComponent;
