import "./App.css";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/home";
import Login from "./pages/login";
import NotFound from "./pages/not-found";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    const kraken = sessionStorage.getItem("krakenC");
    const token = sessionStorage.getItem("krakenT");

    if (!kraken && !token) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
