import http from "../http-common";

const login = (username, password) => {
  return http.post(`/api/v1/auth/signin`, {
    email: username,
    password: password,
  });
};

const logout = () => {
  sessionStorage.removeItem("krakenC");
  sessionStorage.removeItem("krakenT");
};
const LoginService = {
  login,
  logout,
};

export default LoginService;
